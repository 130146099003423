const AppReducer = (state, action) => {

  console.log(action)
  
  switch (action.type) {
    case "login":
      state.user = action.payload;
      break;
    case "logout":
      state.user = null;
      break;
    case "isDarkMode":
      state.isDarkMode = action.payload;
      break;
    case "changeStatus":
      state.status = action.payload;
      break;
    case "setStatusActive":
      state.status = "active";
      break;
    case "setStatusError":
      state.status = "error";
      state.errorMessage = action.errorMessage;
      break;
    case "setStatusLoading":
      state.status = "loading";
      break;
    case "setStatusSaving":
      state.status = "saving";
      break;
    case "setRestaurants":
      state.restaurants = action.payload;
	  break;
	  case "setRunners":
	    state.runners = action.payload;
    break;
    case "setUsers":
      state.users = action.payload;
    break;
    default:
      throw new Error("Invalid Action Type");
  }
  return state;
};

export default AppReducer;
