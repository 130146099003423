import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import AppDispatch from "../contexts/AppDispatch";
import AppState from "../contexts/AppState";
import PrivateRoute from "../hocs/PrivateRoute";
import { useImmerReducer } from "use-immer";
import AppReducer from "./AppReducer";
import InitialState from "./InitialState";
import { ThemeProvider, CssBaseline, createMuiTheme } from "@material-ui/core";
// import theme from "../Components/Theme";
import yellow from '@material-ui/core/colors/yellow'
import Loader from "../Components/Loader/Loader";
import useDispatchMulti from "../hooks/useDispatchMulti";
import api from "../services/api";
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css'

//Lazy loaded components.
const RunnerView = lazy(() => import("../Views/RunnerView/RunnerView"));
const GlobalSettingsView = lazy(() => import("../Views/GlobalSettingsView/GlobalSettingsView"));
const ErrorView = lazy(() => import("../Views/ErrorView/ErrorView"));
const NotFoundPage = lazy(() => import("../Components/NotFound/NotFound"));
const SignUpView = lazy(() => import("../Views/SignUpView/SignUpView"));
const LoginView = lazy(() => import("../Views/LoginView/LoginView"));
const LoadingView = lazy(()=> import("../Views/LoadingView/LoadingView"));
const ForgotPassword = lazy(() => import("../Components/ForgotPassword/ForgotPassword"));
const ResetPassword = lazy(() => import("../Components/ResetPassword/ResetPassword"));
const VerifyUser = lazy(() => import("../Components/VerifyUser/VerifyUser"));
const RestaurantsView = lazy(() => import("../Views/RestaurantsView/RestaurantsView"));
const RestaurantView = lazy(() => import("../Views/RestaurantView/RestaurantView"));
const AddRestaurantView = lazy(() => import("../Views/AddRestaurantView/AddRestaurantView"));
const AddRunnerView = lazy(() => import("../Views/AddRunnerView/AddRunnerView"));
const RunnersView = lazy(() => import("../Views/RunnersView/RunnersView"));
const RunnerMessageHistoryView = lazy(() => import("../Views/RunnerMessageHistoryView/RunnerMessageHistoryView"));
const RunnerOrderHistoryView = lazy(() => import("../Views/RunnerOrderHistoryView/RunnerOrderHistoryView"));

const App = () => {
  const [state, dispatch] = useImmerReducer(AppReducer, InitialState);
  const dispatchMulti = useDispatchMulti(dispatch)

  const darkTheme = createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: yellow[500],
      },
    }
  });

  const theme = createMuiTheme({
    secondary: {
      // main: '#657285'
      main: '#FFD966'
    },
    palette1: {
      red: "#990000",
      black: "#000",
      white: "#fff"
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "setStatusLoading" })

      // Set initial mode
      let mode = JSON.parse(localStorage.getItem('isDarkMode')) || false;
      dispatch({type: 'isDarkMode', payload: JSON.stringify(mode)})

      // We wrap the initial current check in a try by itself because
      // we don't want to error if there is no current user we just
      // want to redirect to login
      try {
        const user = await api('get', "v1/auth/current")
        dispatchMulti([
          { type: "login", payload: user },
          { type: "setStatusActive" }
        ])
      }

      catch (err) {
        dispatch({ type: "setStatusActive" })
      }
    };
    fetchData()
  }, [])

  const switchDisplayMode = () => {
    let mode = !JSON.parse(localStorage.getItem('isDarkMode')) || false;

    console.log(mode)

    localStorage.setItem('isDarkMode', JSON.stringify(mode))
    dispatch({type: 'isDarkMode', payload: JSON.stringify(mode)})
  }

  // const displayMode = localStorage.getItem('isDarkMode')

  const getHomeRoute = (user) => {
    if (user) {
      switch (user.userType) {
        case "admin":
          return "/restaurants";
        case "vendor":
          return "/restaurants";
        case "runner":
          return `/runners/${user.id}`
        default:
          return "/login";
      }
    } else {
      return "/login";
    }
  };

  return (
    <AppDispatch.Provider value={dispatch}>
      <AppState.Provider value={state}>
        <ThemeProvider theme={state.isDarkMode === 'true' ? darkTheme : theme}>
          <CssBaseline />
          <NotificationContainer />
          <Suspense fallback={<Loader />}>
            {state.status === "error" ? <ErrorView isDarkMode={state.isDarkMode} errorMessage={state.errorMessage} /> : null}
            {state.status === "loading" ? <LoadingView/> :
              <Switch>
                <Route exact path="/">
                  <Redirect to={getHomeRoute(state.user)} />
                </Route>
                <Route exact path="/login" component={LoginView} />
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/reset-password" component={ResetPassword} />
                <Route exact path="/reset-password/:verification" component={ResetPassword} />
                <Route exact path="/sign-up" component={SignUpView} />
                <Route exact path="/sign-up/:username" component={VerifyUser} />
                <PrivateRoute exact path="/global-settings" component={GlobalSettingsView} switchDisplayMode={switchDisplayMode} />
                <PrivateRoute exact path="/restaurants" component={RestaurantsView} switchDisplayMode={switchDisplayMode} />
                <PrivateRoute exact path="/add-restaurant" component={AddRestaurantView} />
                <PrivateRoute exact path="/add-runner" component={AddRunnerView} />
                <PrivateRoute path="/restaurants/:id" component={RestaurantView} switchDisplayMode={switchDisplayMode} />
                <PrivateRoute exact path="/runners" component={RunnersView} switchDisplayMode={switchDisplayMode}/>
                <PrivateRoute exact path="/runners/:id" component={RunnerView} switchDisplayMode={switchDisplayMode} />
                <PrivateRoute exact path="/runners/:id/message-history" component={RunnerMessageHistoryView} />
                <PrivateRoute exact path="/runners/:id/order-history" component={RunnerOrderHistoryView} />
                <Route exact path="/404" component={NotFoundPage} />
                <Route path="*">
                  <Redirect to="/404" />
                </Route>
              </Switch>
            }
          </Suspense>
        </ThemeProvider>
      </AppState.Provider>
    </AppDispatch.Provider>
  );
};

export default App;
