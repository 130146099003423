const InitialState = {
  status: "loading",
  user: null,
  errorMessage: null,
  runners: [],
  users: [],
  isDarkMode: localStorage.getItem('isDarkMode')
};

export default InitialState;
