import { BASE_URL } from './api/constants';

export default (method, url, data, options) => {
  options = options || {};
  let config = {
    method: method.toUpperCase(),
    headers: {},
    credentials: 'include'
  };

  if (options.formData) {
    config = Object.assign(config, { body: data });
  }

  else if (['post', 'put', 'delete', 'patch'].includes(method.toLowerCase())) {
    config = Object.assign(config, { body: JSON.stringify(data) });
  }

  if (!options.formData) {
    config.headers['Content-Type'] = 'application/json; charset=utf-8';
  }

  function getPromiseType(res) {
    const contentType = res.headers.get('content-type') || '';

    if (res.status === 204) return res.text(); // 204 = No Content, so no need to parse
    if (contentType.includes('json')) return res.json();
    else if (contentType.includes('svg')) return res.text();
    else if (contentType.includes('image')) return res.blob();
    else return res.text();
  }

  function toData(resolve) {
    return _res => {
      getPromiseType(_res).then(res => {
        if (!res) return resolve();
        resolve(res);
      });
    };
  }

  function catchError(reject) {
    return _res => {
      getPromiseType(_res).then(err => {
        console.error('Api', _res.status, _res.statusText + ':', err.message);
        reject(err);
      });
    };
  }

  return new Promise(async (resolve, reject) => {
    var handleData = toData(resolve);
    var handleError = catchError(reject);

    return fetch(`${BASE_URL}/${url}`, config)
      .then(res => {
        if (!res.ok) return handleError(res);
        else return handleData(res);
      })
      .catch(err => console.error('Api', err));
  });
}
