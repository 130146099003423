import React from 'react';
import useAppState from '../hooks/useAppState';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, ...rest }) => {

	console.log(rest)
	const state = useAppState();

	return (
		<Route
			{...rest}
			render={(props) =>
				state.user ? (
					<Component {...props} switchDisplayMode={rest.switchDisplayMode} />
				) : (
					<Redirect to={{ pathname: '/', state: { from: props.location } }} />
				)}
		/>
	);
};

PrivateRoute.propTypes = {
	Component: PropTypes.object,
	rest: PropTypes.object
};

export default PrivateRoute;
