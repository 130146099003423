export const BASE_URL = process.env.REACT_APP_API_HOST || 'http://localhost:8081';

export const TAGS = [
	{ id: 120, label: "Bowls", value: "bowls", isFixed: true },
	{ id: 121, label: "Sandwiches", value: "sandwiches", isFixed: true },
	{ id: 122, label: "Vegetarian", value: "vegetarian", isFixed: true },
	{ id: 123, label: "Meat Lover", value: "meat lover", isFixed: true },
	{ id: 124, label: "Healthy", value: "healthy", isFixed: true },
	{ id: 125, label: "Keto", value: "keto", isFixed: true },
	{ id: 126, label: "Gluten Free", value: "gluten free", isFixed: true },
	{ id: 127, label: "Sides", value: "sides", isFixed: true },
	{ id: 128, label: "Sweets", value: "sweets", isFixed: true },
];

export const VIEW_STATUS_OPTIONS = {
	infoActive: {
		info: { status: "active", icon: "active1" },
		ingredients: { status: "pending", icon: "pending2" },
		options: { status: "pending", icon: "pending3" },
	},
	ingredientsActive: {
		info: { status: "complete", icon: "complete1" },
		ingredients: { status: "active", icon: "active2" },
		options: { status: "pending", icon: "pending3" },
	},
	optionsActive: {
		info: { status: "complete", icon: "complete1" },
		ingredients: { status: "complete", icon: "complete2" },
		options: { status: "active", icon: "active3" },
	},
	completedState: {
		info: { status: "complete", icon: "complete1" },
		ingredients: { status: "complete", icon: "complete2" },
		options: { status: "complete", icon: "complete3" },
	},
};
