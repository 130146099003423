import { makeStyles, createStyles } from '@material-ui/core';  

const AppStyles = makeStyles(theme => createStyles({
  loaderContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

export default AppStyles
