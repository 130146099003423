import React from 'react';
import AppStyles from '../../App/AppStyles';
import CircularProgress from "@material-ui/core/CircularProgress";


const Loader = () => {
	const classes = AppStyles();

	return (
		<div className={classes.loaderContainer}>
			{/* <h1>Loading...</h1> */}
			<CircularProgress />
		</div>
	);
};

export default Loader;
